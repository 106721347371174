import React from 'react';
import * as PropTypes from 'prop-types';

export default function functionOrMapChildren(children, withProps) {
  if (children && children.constructor && children.call && children.apply) {
    return children(withProps) || React.createElement(
      'div',
      null,
      'Could not render children from function'
    );
  }

  return React.Children.map(children, function (child) {
    return React.cloneElement(child, withProps);
  }) || React.createElement(
    'div',
    null,
    'Could not clone children'
  );
}

export var FunctionOrMapChildrenType = PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.node), PropTypes.node]);