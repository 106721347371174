var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _class, _temp2;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import * as Manifesto from 'manifesto.js';

var StaticImageViewport = (_temp2 = _class = function (_Component) {
  _inherits(StaticImageViewport, _Component);

  function StaticImageViewport() {
    var _temp, _this, _ret;

    _classCallCheck(this, StaticImageViewport);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = { dragging: false }, _this.onDrag = function (ratio) {
      return function (e, data) {
        if (_this.props.getPosition) {
          _this.props.getPosition({
            x: data.x,
            y: data.y,
            zoom: 1 / ratio,
            scale: 1,
            rotation: 0
          });
        }
      };
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  StaticImageViewport.prototype.componentWillMount = function componentWillMount() {
    var ratio = this.getRatio();

    if (this.props.getPosition) {
      this.props.getPosition({
        x: 0,
        y: 0,
        zoom: 1 / ratio,
        scale: 1,
        rotation: 0
      });
    }
  };

  StaticImageViewport.prototype.getRatio = function getRatio() {
    var _props = this.props,
        height = _props.height,
        width = _props.width,
        maxHeight = _props.maxHeight,
        maxWidth = _props.maxWidth;

    var heightRatio = height / (maxHeight || 500);
    var widthRatio = width / (maxWidth || maxHeight || 500);
    var ratio = heightRatio > widthRatio ? heightRatio : widthRatio;

    if (Number.isNaN(ratio)) {
      return 1;
    }
  };

  StaticImageViewport.prototype.getTargetWidth = function getTargetWidth(ratio) {
    var width = this.props.width;

    var targetWidth = Math.floor(width / ratio);
    if (Number.isNaN(targetWidth)) {
      return 0;
    }
    return targetWidth;
  };

  StaticImageViewport.prototype.render = function render() {
    var _props2 = this.props,
        canvas = _props2.canvas,
        className = _props2.className,
        children = _props2.children,
        width = _props2.width,
        draggable = _props2.draggable,
        onClick = _props2.onClick,
        style = _props2.style;

    var ratio = this.getRatio();
    var targetWidth = this.getTargetWidth(ratio);
    var pixelRatio = window.devicePixelRatio || 1;

    var body = React.createElement(
      'div',
      { className: className, onClick: onClick },
      React.createElement('img', {
        src: canvas.getCanonicalImageUri(Math.floor(targetWidth * pixelRatio)),
        style: _extends({
          width: targetWidth ? targetWidth : 'auto',
          pointerEvents: 'none',
          maxWidth: '100%'
        }, style)
      }),
      children
    );

    if (draggable) {
      return React.createElement(
        Draggable,
        { onDrag: this.onDrag(ratio) },
        body
      );
    }

    return body;
  };

  return StaticImageViewport;
}(Component), _class.defaultProps = {
  maxHeight: 0,
  maxWidth: 0
}, _temp2);
StaticImageViewport.propTypes = process.env.NODE_ENV !== "production" ? {
  maxHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  draggable: PropTypes.bool,
  onClick: PropTypes.func,
  canvas: PropTypes.any
} : {};


export default StaticImageViewport;