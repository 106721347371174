function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { PureComponent } from 'react';
import MobileDetect from 'mobile-detect';
import functionOrMapChildren from './functionOrMapChildren';

var Responsive = function (_PureComponent) {
  _inherits(Responsive, _PureComponent);

  function Responsive() {
    _classCallCheck(this, Responsive);

    return _possibleConstructorReturn(this, _PureComponent.apply(this, arguments));
  }

  Responsive.isMobile = function isMobile() {
    return !!Responsive.md.phone();
  };

  Responsive.prototype.render = function render() {
    var _props = this.props,
        tabletDown = _props.tabletDown,
        phoneOnly = _props.phoneOnly,
        tabletOnly = _props.tabletOnly,
        desktopOnly = _props.desktopOnly,
        is = _props.is,
        children = _props.children,
        props = _objectWithoutProperties(_props, ['tabletDown', 'phoneOnly', 'tabletOnly', 'desktopOnly', 'is', 'children']);

    if (tabletDown && phoneOnly || tabletDown && tabletOnly) {
      console.warn("tabletDown is ignored because you've passed down multiple options.");
    }

    if (is && Responsive.md.is(is)) {
      return functionOrMapChildren(children, props);
    }

    if (phoneOnly && Responsive.md.phone()) {
      return phoneOnly(props);
    }

    if (tabletOnly && Responsive.md.tablet()) {
      return tabletOnly(props);
    }

    if (tabletDown && Responsive.md.mobile()) {
      return tabletDown(props);
    }

    return desktopOnly ? desktopOnly(props) : children ? functionOrMapChildren(children, props) : null;
  };

  return Responsive;
}(PureComponent);

Responsive.md = new MobileDetect(window.navigator.userAgent);

export default Responsive;