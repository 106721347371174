var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _class, _temp2;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as Manifesto from 'manifesto.js';
import LocaleString from '../LocaleString/LocaleString';
import functionOrMapChildren, { FunctionOrMapChildrenType } from '../../utility/functionOrMapChildren';

var CanvasProvider = (_temp2 = _class = function (_Component) {
  _inherits(CanvasProvider, _Component);

  function CanvasProvider() {
    var _temp, _this, _ret;

    _classCallCheck(this, CanvasProvider);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      currentCanvas: null
    }, _this.dispatch = function (action) {
      _this.setState(CanvasProvider.reducer(_this.state, action));
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  CanvasProvider.prototype.componentWillReceiveProps = function componentWillReceiveProps(newProps) {
    if (newProps.currentCanvas !== this.props.currentCanvas) {
      var _sequence = newProps.manifest.getSequenceByIndex(newProps.sequence);
      this.setState({
        currentCanvas: Number.isInteger(newProps.currentCanvas) ? newProps.currentCanvas : _sequence.getCanvasIndexById(newProps.currentCanvas)
      });
    }
  };

  CanvasProvider.reducer = function reducer(state, action) {
    switch (action.type) {
      case CanvasProvider.NEXT_CANVAS:
        return _extends({}, state, { currentCanvas: state.currentCanvas + 1 });
      case CanvasProvider.PREV_CANVAS:
        return _extends({}, state, {
          currentCanvas: state.currentCanvas === 0 ? 0 : state.currentCanvas - 1
        });
    }
    return state;
  };

  CanvasProvider.nextCanvas = function nextCanvas() {
    return { type: CanvasProvider.NEXT_CANVAS };
  };

  CanvasProvider.prevCanvas = function prevCanvas() {
    return { type: CanvasProvider.PREV_CANVAS };
  };

  CanvasProvider.prototype.componentWillMount = function componentWillMount() {
    if (this.props.startCanvas !== this.state.currentCanvas) {
      this.setState({ currentCanvas: this.props.startCanvas });
    }
  };

  CanvasProvider.prototype.render = function render() {
    var _props = this.props,
        manifest = _props.manifest,
        startCanvas = _props.startCanvas,
        children = _props.children;
    var currentCanvas = this.state.currentCanvas;


    var sequence = manifest.getSequenceByIndex(this.props.sequence);
    var canvas = Number.isInteger(currentCanvas) ? sequence.getCanvasByIndex(currentCanvas) : sequence.getCanvasById(currentCanvas);

    return functionOrMapChildren(children, {
      manifest: manifest,
      sequence: sequence,
      canvas: canvas,
      startCanvas: startCanvas,
      currentCanvas: currentCanvas,
      dispatch: this.dispatch,
      width: canvas.getWidth(),
      height: canvas.getHeight()
    });
  };

  return CanvasProvider;
}(Component), _class.defaultProps = {
  sequence: 0,
  startCanvas: 0
}, _class.NEXT_CANVAS = 'NEXT_CANVAS', _class.PREV_CANVAS = 'PREV_CANVAS', _temp2);
CanvasProvider.propTypes = process.env.NODE_ENV !== "production" ? {
  sequence: PropTypes.number,
  startCanvas: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // manifest: PropTypes.instanceOf(Manifesto.Manifest),
  children: FunctionOrMapChildrenType
} : {};


export default CanvasProvider;