var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import screenfull from 'screenfull';
import functionOrMapChildren from '../../utility/functionOrMapChildren';

var Fullscreen = function (_Component) {
  _inherits(Fullscreen, _Component);

  function Fullscreen() {
    var _temp, _this, _ret;

    _classCallCheck(this, Fullscreen);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      isFullscreen: false
    }, _this.handleChangeFullScreenState = function () {
      _this.setState(function () {
        return { isFullscreen: screenfull.isFullscreen };
      });
    }, _this.toggleFullscreen = function () {
      if (_this.state.isFullscreen) {
        _this.exitFullscreen();
      } else {
        _this.goFullscreen();
      }
    }, _this.goFullscreen = function () {
      if (!_this.refNode) {
        console.error('BREAKING CHANGE: to use fullscreen component you must use the passed in `ref` property');
        return;
      }
      var node = ReactDOM.findDOMNode(_this.refNode);
      if (node) {
        screenfull.request(node);
      }
    }, _this.setRef = function (refNode) {
      _this.refNode = refNode;
    }, _this.exitFullscreen = function () {
      if (!_this.refNode) {
        console.error('BREAKING CHANGE: to use fullscreen component you must use the passed in `ref` property');
        return;
      }
      var node = ReactDOM.findDOMNode(_this.refNode);
      if (node) {
        screenfull.exit(node);
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  Fullscreen.prototype.componentWillMount = function componentWillMount() {
    if (screenfull.on) {
      screenfull.on('change', this.handleChangeFullScreenState);
    }
  };

  Fullscreen.prototype.componentWillUnmount = function componentWillUnmount() {
    if (screenfull.off) {
      screenfull.off('change', this.handleChangeFullScreenState);
    }
  };

  Fullscreen.prototype.render = function render() {
    var _props = this.props,
        children = _props.children,
        props = _objectWithoutProperties(_props, ['children']);

    var isFullscreen = this.state.isFullscreen;


    return functionOrMapChildren(children, _extends({
      fullscreenEnabled: screenfull.enabled,
      isFullscreen: isFullscreen,
      toggleFullscreen: this.toggleFullscreen,
      goFullscreen: this.goFullscreen,
      exitFullscreen: this.exitFullscreen,
      ref: this.setRef
    }, props));
  };

  return Fullscreen;
}(Component);

export default Fullscreen;