var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _class, _temp;

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as Manifesto from 'manifesto.js';

var CanvasRepresentation = (_temp = _class = function (_Component) {
  _inherits(CanvasRepresentation, _Component);

  function CanvasRepresentation() {
    _classCallCheck(this, CanvasRepresentation);

    return _possibleConstructorReturn(this, _Component.apply(this, arguments));
  }

  CanvasRepresentation.prototype.processChildStyle = function processChildStyle(child) {
    var _props = this.props,
        position = _props.position,
        ratio = _props.ratio;

    var _ref = child.props.style || {},
        maxHeight = _ref.maxHeight,
        style = _objectWithoutProperties(_ref, ['maxHeight']);

    var computedMaxHeight = maxHeight ? 'auto' : maxHeight * ratio;

    if (child.props.growthStyle === 'fixed') {
      var zam = position ? position.zoom * (1 / ratio) : 1;
      var fixedMaxHeight = maxHeight && zam ? maxHeight / (1 / zam) : 'auto';
      return _extends({}, style, {
        position: 'absolute',
        top: child.props.y * ratio,
        left: child.props.x * ratio,
        height: child.props.height * ratio * zam,
        width: child.props.width * ratio * zam,
        maxHeight: fixedMaxHeight,
        transform: 'scale(' + 1 / zam + ')',
        transformOrigin: 'top left'
      });
    }

    if (child.props.growthStyle === 'absolute') {
      // Do opposite of above, make double the size and scale it down.
    }

    return _extends({}, style, {
      position: 'absolute',
      top: child.props.y * ratio,
      left: child.props.x * ratio,
      height: child.props.height * ratio,
      width: child.props.width * ratio,
      maxHeight: computedMaxHeight
    });
  };

  CanvasRepresentation.prototype.render = function render() {
    var _this2 = this;

    var _props2 = this.props,
        canvas = _props2.canvas,
        style = _props2.style,
        ratio = _props2.ratio,
        children = _props2.children,
        height = _props2.height,
        width = _props2.width,
        props = _objectWithoutProperties(_props2, ['canvas', 'style', 'ratio', 'children', 'height', 'width']);

    return React.createElement(
      'div',
      {
        style: _extends({
          position: 'relative',
          height: height * ratio,
          width: width * ratio,
          pointerEvents: 'none'
        }, style)
      },
      React.Children.map(children, function (child) {
        var propsForEl = child.type === 'div' ? {} : _extends({ canvas: canvas }, props);
        return React.cloneElement(child, _extends({
          style: _this2.processChildStyle(child)
        }, propsForEl));
      })
    );
  };

  return CanvasRepresentation;
}(Component), _class.defaultProps = {
  maxWidth: 500,
  ratio: 1
}, _temp);
CanvasRepresentation.propTypes = process.env.NODE_ENV !== "production" ? {
  // canvas: PropTypes.instanceOf(Manifesto.Canvas),
} : {};


export default CanvasRepresentation;