var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as Manifesto from 'manifesto.js';
import functionOrMapChildren, { FunctionOrMapChildrenType } from '../../utility/functionOrMapChildren';

var AnnotationListProvider = function (_Component) {
  _inherits(AnnotationListProvider, _Component);

  function AnnotationListProvider() {
    var _temp, _this, _ret;

    _classCallCheck(this, AnnotationListProvider);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = { annotationLists: null }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  AnnotationListProvider.prototype.componentDidMount = function componentDidMount() {
    this.propsToState(this.props);
  };

  AnnotationListProvider.prototype.componentWillReceiveProps = function componentWillReceiveProps(newProps) {
    if (newProps.canvas !== this.props.canvas) {
      this.propsToState(newProps);
    }
  };

  AnnotationListProvider.prototype.propsToState = function propsToState(_ref) {
    var _this2 = this;

    var canvas = _ref.canvas;

    if (canvas) {
      this.setState({ annotationLists: null });
      canvas.getOtherContent().then(function (content) {
        _this2.setState({ annotationLists: content });
      });

      canvas.getAnnotations().then(function (annotations) {
        _this2.setState({ annotationLists: annotations });
      });
    }
  };

  AnnotationListProvider.prototype.render = function render() {
    var _props = this.props,
        children = _props.children,
        canvas = _props.canvas,
        props = _objectWithoutProperties(_props, ['children', 'canvas']);

    var annotationLists = this.state.annotationLists;


    if (!canvas || !annotationLists) {
      return null;
    }

    return React.createElement(
      'div',
      null,
      annotationLists.map(function (annotationList, key) {
        return React.createElement(
          'div',
          { key: key },
          functionOrMapChildren(children, _extends({
            canvas: canvas,
            annotationList: annotationList
          }, props))
        );
      })
    );
  };

  return AnnotationListProvider;
}(Component);

AnnotationListProvider.propTypes = process.env.NODE_ENV !== "production" ? {
  // canvas: PropTypes.instanceOf(Manifesto.Canvas),
  children: FunctionOrMapChildrenType
} : {};


export default AnnotationListProvider;