var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _class, _temp2;

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import SizedViewport from '../SizedViewport/SizedViewport';

var FullPageViewport = (_temp2 = _class = function (_Component) {
  _inherits(FullPageViewport, _Component);

  function FullPageViewport() {
    var _temp, _this, _ret;

    _classCallCheck(this, FullPageViewport);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      position: {}
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  FullPageViewport.prototype.componentDidMount = function componentDidMount() {
    if (this.props.getContainer) {
      var container = this.props.getContainer();
      try {
        var _container$getBoundin = container.getBoundingClientRect(),
            top = _container$getBoundin.top,
            left = _container$getBoundin.left,
            width = _container$getBoundin.width,
            height = _container$getBoundin.height;

        this.setState({ position: { top: top, left: left, width: width, height: height } });
      } catch (e) {
        console.warn(e);
      }
    }
  };

  FullPageViewport.prototype.render = function render() {
    var _props = this.props,
        children = _props.children,
        style = _props.style,
        zIndex = _props.zIndex,
        interactive = _props.interactive,
        position = _props.position,
        props = _objectWithoutProperties(_props, ['children', 'style', 'zIndex', 'interactive', 'position']);

    var computedStyle = _extends({
      position: position === 'fixed' ? 'fixed' : 'absolute',
      top: this.state.top,
      left: this.state.left,
      zIndex: zIndex || 10
    }, this.state.position, style);

    if (position === 'fixed' && !computedStyle.height) {
      computedStyle.bottom = 0;
    }

    if (position === 'fixed' && !computedStyle.width) {
      computedStyle.right = 0;
    }

    if (interactive === false) {
      computedStyle.pointerEvents = 'none';
    }

    if (!computedStyle.width) {
      computedStyle.width = '100%';
    }

    return React.createElement(
      SizedViewport,
      _extends({ style: computedStyle }, props),
      children
    );
  };

  return FullPageViewport;
}(Component), _class.defaultProps = {
  style: {},
  interactive: false,
  position: 'fixed'
}, _temp2);


export default FullPageViewport;