var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _class, _temp2;

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as Manifesto from 'manifesto.js';
import getDataUriFromCanvas from '../../utility/getDataUriFromCanvas';
import functionOrMapChildren, { FunctionOrMapChildrenType } from '../../utility/functionOrMapChildren';

var SingleTileSource = (_temp2 = _class = function (_Component) {
  _inherits(SingleTileSource, _Component);

  function SingleTileSource() {
    var _temp, _this, _ret;

    _classCallCheck(this, SingleTileSource);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      imageUri: null,
      tileSources: []
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  SingleTileSource.prototype.componentWillMount = function componentWillMount() {
    var imageUri = getDataUriFromCanvas(this.props.canvas);
    this.updateImageUri(imageUri);
  };

  SingleTileSource.prototype.updateImageUri = function updateImageUri(imageUri) {
    var _this2 = this;

    if (!imageUri) {
      return null;
    }

    if (!imageUri.endsWith('/info.json')) {
      this.setState({
        imageUri: imageUri,
        tileSources: [{
          type: 'image',
          url: imageUri
        }]
      });
      return;
    }

    if (!SingleTileSource.cache[imageUri]) {
      SingleTileSource.cache[imageUri] = fetch(imageUri).then(function (resp) {
        return resp.json();
      });
    }

    return SingleTileSource.cache[imageUri].then(function (tileSource) {
      _this2.setState({
        imageUri: imageUri,
        tileSources: [tileSource]
      });
    });
  };

  SingleTileSource.prototype.componentWillReceiveProps = function componentWillReceiveProps(newProps) {
    var imageUri = getDataUriFromCanvas(newProps.canvas);
    if (imageUri !== this.state.imageUri) {
      this.updateImageUri(imageUri);
    }
  };

  SingleTileSource.prototype.renderFallback = function renderFallback() {
    var _props = this.props,
        canvas = _props.canvas,
        fallbackWidth = _props.fallbackWidth;
    var tileSources = this.state.tileSources;


    var tileSource = tileSources[0];

    var fallbackImageUrl = tileSource && tileSource.type === 'image' ? tileSource.url : canvas.getCanonicalImageUri(fallbackWidth);

    return React.createElement(
      'div',
      null,
      React.createElement('img', { width: fallbackWidth, src: fallbackImageUrl })
    );
  };

  SingleTileSource.prototype.render = function render() {
    var _props2 = this.props,
        children = _props2.children,
        fallbackWidth = _props2.fallbackWidth,
        canvas = _props2.canvas,
        preLoad = _props2.preLoad,
        props = _objectWithoutProperties(_props2, ['children', 'fallbackWidth', 'canvas', 'preLoad']);

    var _state = this.state,
        imageUri = _state.imageUri,
        tileSources = _state.tileSources;


    if (tileSources === null) {
      return 'loading tile source';
    }

    // Render children if they exist.
    if (children) {
      var childrenRender = functionOrMapChildren(children, _extends({
        canvas: canvas,
        imageUri: imageUri,
        tileSources: tileSources
      }, props));
      if (childrenRender) {
        return childrenRender;
      }
    }

    if (preLoad) {
      return preLoad(this.props);
    }

    return this.renderFallback();
  };

  return SingleTileSource;
}(Component), _class.defaultProps = {
  fallbackWidth: 200
}, _class.cache = {}, _temp2);
SingleTileSource.propTypes = process.env.NODE_ENV !== "production" ? {
  // canvas: PropTypes.instanceOf(Manifesto.Canvas),
  // manifest: PropTypes.instanceOf(Manifesto.Manifest),
  preLoad: PropTypes.func,
  children: FunctionOrMapChildrenType,
  fallbackWidth: PropTypes.number
} : {};


export default SingleTileSource;