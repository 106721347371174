var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _class, _temp2;

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from 'react';
import PropTypes from 'prop-types';
import detectIt from 'detect-it';
import AnnotationResizers from '../AnnotationResizers/AnnotationResizers';

var emptyFn = function emptyFn() {};
var EditableAnnotation = (_temp2 = _class = function (_React$Component) {
  _inherits(EditableAnnotation, _React$Component);

  function EditableAnnotation() {
    var _temp, _this, _ret;

    _classCallCheck(this, EditableAnnotation);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _React$Component.call.apply(_React$Component, [this].concat(args))), _this), _this.state = {
      mouseX: 0,
      mouseY: 0,
      dX: 0,
      dY: 0,
      dWidth: 0,
      dHeight: 0,
      dragStarted: false,
      resizeStarted: false
    }, _this.attachNativeHandlers = function () {
      if (detectIt.hasMouse) {
        document.addEventListener('mousemove', _this.onPointerMove);
        document.addEventListener('mouseup', _this.onPointerUp);
      }
      if (detectIt.hasTouch) {
        document.addEventListener('touchmove', _this.onPointerMove, {
          passive: false
        });
        document.addEventListener('touchend', _this.onPointerUp, {
          passive: false
        });
      }
    }, _this.detachNativeHandlers = function () {
      if (detectIt.hasMouse) {
        document.removeEventListener('mousemove', _this.onPointerMove);
        document.removeEventListener('mouseup', _this.onPointerUp);
      }
      if (detectIt.hasTouch) {
        document.removeEventListener('touchmove', _this.onPointerMove);
        document.removeEventListener('touchend', _this.onPointerUp);
      }
    }, _this.onPointerMove = function (ev) {
      if (ev.type !== 'mousemove' && ev.touches.length !== 1) {
        return;
      }
      if (ev.type === 'mousemove') {
        ev.stopPropagation();
      } else if (ev.type === 'touchmove') {
        ev.preventDefault();
      }
      var X = ev.type === 'mousemove' ? ev.pageX : ev.touches[0].pageX;
      var Y = ev.type === 'mousemove' ? ev.pageY : ev.touches[0].pageY;
      var _this$props = _this.props,
          position = _this$props.position,
          ratio = _this$props.ratio;

      var zam = position ? position.zoom * (1 / ratio) : 1;
      var rzam = 1 / zam;
      if (_this.state.dragStarted) {
        _this.dragMove(X, Y, rzam);
      } else if (_this.state.resizeStarted) {
        _this.resizeMove(X, Y, rzam);
      }
    }, _this.onPointerUp = function (ev) {
      if (ev.type === 'mouseup') {
        ev.stopPropagation();
      } else if (ev.type === 'touchend') {
        ev.preventDefault();
      }
      _this.detachNativeHandlers();
      if (_this.state.dragStarted) {
        _this.dragEnd();
      } else if (_this.state.resizeStarted) {
        _this.resizeEnd();
      }
    }, _this.dragStart = function (ev) {
      if (ev.type === 'mousedown') {
        ev.stopPropagation();
      } else if (ev.type === 'touchstart') {
        ev.preventDefault();
      }
      _this.attachNativeHandlers();
      _this.setState({
        mouseX: ev.type === 'mousedown' ? ev.pageX : ev.touches[0].pageX,
        mouseY: ev.type === 'mousedown' ? ev.pageY : ev.touches[0].pageY,
        dX: 0,
        dY: 0,
        dWidth: 0,
        dHeight: 0,
        dragStarted: true,
        resizeStarted: false
      });
    }, _this.dragMove = function (X, Y, rzam) {
      if (_this.props.setCoords && _this.state.dragStarted) {
        _this.setState(_this.applyDragConstraints({
          dX: _this.applyPrecision((X - _this.state.mouseX) * rzam),
          dY: _this.applyPrecision((Y - _this.state.mouseY) * rzam),
          dragStarted: true,
          resizeStarted: false
        }));
      }
    }, _this.dragEnd = function () {
      _this.props.setCoords({
        x: _this.props.x + _this.state.dX,
        y: _this.props.y + _this.state.dY
      });
      _this.setState({
        dragStarted: false,
        resizeStarted: false,
        dX: 0,
        dY: 0
      });
    }, _this.resizeStart = function (direction) {
      return function (ev) {
        ev.stopPropagation();
        _this.attachNativeHandlers();
        _this.setState({
          mouseX: ev.type === 'mousedown' ? ev.pageX : ev.touches[0].pageX,
          mouseY: ev.type === 'mousedown' ? ev.pageY : ev.touches[0].pageY,
          dWidth: 0,
          dHeight: 0,
          dX: 0,
          dY: 0,
          resizeStarted: direction,
          dragStarted: false
        });
      };
    }, _this.resizeMove = function (X, Y, rzam) {
      if (_this.props.setCoords && _this.state.resizeStarted) {
        if (!_this.state.resizeStarted) {
          return;
        }
        var newState = {
          dragStarted: false
        };
        var canvas = _this.props.canvas.__jsonld;
        var ratio = _this.props.ratio;

        if (_this.state.resizeStarted.startsWith('n')) {
          newState.dHeight = _this.applyPrecision(-((Y - _this.state.mouseY) * rzam));
          newState.dY = _this.applyPrecision((Y - _this.state.mouseY) * rzam);
          if (_this.props.constrainToCanvasBounds) {
            newState.dY = Math.min(Math.max(-_this.props.y || 0, newState.dY), _this.props.height);
            newState.dHeight = Math.min(_this.props.y, Math.max(newState.dHeight, -_this.props.height));
          }
        }
        if (_this.state.resizeStarted.startsWith('s')) {
          newState.dHeight = _this.applyPrecision((Y - _this.state.mouseY) * rzam);
          if (_this.props.constrainToCanvasBounds) {
            newState.dHeight = Math.min(Math.max(newState.dHeight, -_this.props.height), canvas.height * ratio - (_this.props.y + _this.props.height));
          }
        }
        if (_this.state.resizeStarted.endsWith('e')) {
          newState.dWidth = _this.applyPrecision((X - _this.state.mouseX) * rzam);
          if (_this.props.constrainToCanvasBounds) {
            newState.dWidth = Math.min(Math.max(newState.dWidth, -_this.props.width), canvas.width * ratio - (_this.props.x + _this.props.width));
          }
        }
        if (_this.state.resizeStarted.endsWith('w')) {
          newState.dWidth = _this.applyPrecision(-(X - _this.state.mouseX) * rzam);
          newState.dX = _this.applyPrecision((X - _this.state.mouseX) * rzam);
          if (_this.props.constrainToCanvasBounds) {
            newState.dX = Math.min(Math.max(-_this.props.x || 0, newState.dX), _this.props.width);
            newState.dWidth = Math.min(_this.props.x, Math.max(newState.dWidth, -_this.props.width));
          }
        }
        _this.setState(newState);
      }
    }, _this.resizeEnd = function () {
      _this.props.setCoords({
        width: _this.applyPrecision(_this.props.width + _this.state.dWidth),
        height: _this.applyPrecision(_this.props.height + _this.state.dHeight),
        x: _this.applyPrecision(_this.props.x + _this.state.dX),
        y: _this.applyPrecision(_this.props.y + _this.state.dY)
      });
      _this.setState({
        resizeStarted: false,
        dragStarted: false,
        dWidth: 0,
        dHeight: 0,
        dX: 0,
        dY: 0
      });
    }, _this.applyPrecision = function (value) {
      return _this.props.boxSizeInt ? parseInt(value, 10) : value;
    }, _this.applyDragConstraints = function (deltas) {
      if (_this.props.constrainToCanvasBounds) {
        var canvas = _this.props.canvas.__jsonld;
        var _this$props2 = _this.props,
            x = _this$props2.x,
            y = _this$props2.y,
            width = _this$props2.width,
            height = _this$props2.height,
            ratio = _this$props2.ratio;


        deltas.dX = Math.min(Math.max(-x || 0, deltas.dX), canvas.width * ratio - (x + width));

        deltas.dY = Math.min(Math.max(-y || 0, deltas.dY), canvas.height * ratio - (y + height));
      }
      return deltas;
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  EditableAnnotation.prototype.componentDidUpdate = function componentDidUpdate(prevProps, prevState) {
    if ((prevProps.x !== this.props.x || prevProps.y !== this.props.y || prevProps.width !== this.props.width || prevProps.height !== this.props.height) && (prevState.dragStarted || prevState.resizeStarted)) {
      this.detachNativeHandlers();
      this.setState({
        dragStarted: false,
        resizeStarted: false
      });
    }
  };

  EditableAnnotation.prototype.render = function render() {
    var _props = this.props,
        x = _props.x,
        y = _props.y,
        width = _props.width,
        height = _props.height,
        children = _props.children,
        boxStyles = _props.boxStyles,
        className = _props.className,
        ratio = _props.ratio,
        position = _props.position,
        resizerWidth = _props.resizerWidth,
        containerProps = _props.containerProps,
        props = _objectWithoutProperties(_props, ['x', 'y', 'width', 'height', 'children', 'boxStyles', 'className', 'ratio', 'position', 'resizerWidth', 'containerProps']);

    var X = this.applyPrecision(x) + this.state.dX;
    var Y = this.applyPrecision(y) + this.state.dY;
    var zam = position ? position.zoom * (1 / ratio) : 1;
    var rzam = 1 / zam;
    return React.createElement(
      'div',
      _extends({
        style: _extends({}, EditableAnnotation.defaultStyles, boxStyles, {
          transform: 'translate(' + X + 'px, ' + Y + 'px)',
          width: this.applyPrecision(width) + this.state.dWidth,
          height: this.applyPrecision(height) + this.state.dHeight
        }),
        className: className,
        onMouseDown: this.dragStart,
        onTouchStart: this.dragStart
      }, containerProps),
      children,
      React.createElement(AnnotationResizers, {
        resizeStart: this.resizeStart,
        resizerWidth: resizerWidth * rzam
      })
    );
  };

  return EditableAnnotation;
}(React.Component), _class.defaultStyles = {
  outline: '1px solid skyblue',
  background: 'rgba(135, 206, 235, 0.3)',
  pointerEvents: 'all',
  transformOrigin: '0 0',
  position: 'absolute',
  top: 0,
  left: 0
}, _class.defaultProps = {
  boxStyles: {},
  boxSizeInt: true,
  constrainToCanvasBounds: true,
  resizerWidth: 25
}, _temp2);
export { EditableAnnotation as default };
EditableAnnotation.propTypes = process.env.NODE_ENV !== "production" ? {
  boxStyles: PropTypes.object,
  boxSizeInt: PropTypes.bool,
  constrainToCanvasBounds: PropTypes.bool,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  resizerWidth: PropTypes.number
} : {};