var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _class, _temp2;

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import * as Manifesto from 'manifesto.js';
import functionOrMapChildren from '../../utility/functionOrMapChildren';
import extractCanvasAndRegionsFromRange from '../../utility/extractCanvasAndRegionsFromRange';

var RangeNavigationProvider = (_temp2 = _class = function (_Component) {
  _inherits(RangeNavigationProvider, _Component);

  function RangeNavigationProvider() {
    var _temp, _this, _ret;

    _classCallCheck(this, RangeNavigationProvider);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      currentIndex: 0,
      currentRangeObject: null,
      currentRange: null,
      canvasList: [],
      regionList: []
    }, _this.nextRange = function () {
      var _this$state = _this.state,
          currentIndex = _this$state.currentIndex,
          canvasList = _this$state.canvasList;


      if (currentIndex >= canvasList.length - 1) {
        return;
      }

      _this.goToRange(currentIndex + 1);
    }, _this.previousRange = function () {
      var currentIndex = _this.state.currentIndex;


      if (currentIndex === 0) {
        return;
      }

      _this.goToRange(currentIndex - 1);
    }, _this.goToRange = function (newIndex) {
      var controlled = _this.props.controlled;
      var canvasList = _this.state.canvasList;


      if (controlled === false) {
        _this.setState({
          currentIndex: newIndex
        });
      }

      if (_this.props.onNavigate) {
        _this.props.onNavigate(newIndex, canvasList[newIndex]);
      }
    }, _this.getCanvasAtIndex = function (currentIndex) {
      var manifest = _this.props.manifest;
      var canvasList = _this.state.canvasList;


      try {
        return canvasList.length !== 0 ? manifest.getSequenceByIndex(0).getCanvasById(canvasList[currentIndex]) : null;
      } catch (err) {
        return null;
      }
    }, _this.getNextRange = function (currentIndex) {
      return function () {
        var canvasList = _this.state.canvasList;


        if (currentIndex >= canvasList.length - 1) {
          return;
        }

        return _this.getCanvasAtIndex(currentIndex + 1);
      };
    }, _this.getPreviousRange = function (currentIndex) {
      return function () {
        if (currentIndex === 0) {
          return;
        }

        return _this.getCanvasAtIndex(currentIndex - 1);
      };
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  RangeNavigationProvider.prototype.componentWillMount = function componentWillMount() {
    var matchingRange = this.getMatchingRange(this.props);
    if (matchingRange) {
      return this.setState({
        currentRangeObject: matchingRange.range,
        currentRange: matchingRange.id,
        canvasList: matchingRange.canvasList,
        regionList: matchingRange.regionList || []
      });
    }
  };

  RangeNavigationProvider.prototype.getMatchingRange = function getMatchingRange(_ref) {
    var manifest = _ref.manifest,
        rangeId = _ref.rangeId,
        rangeViewingHint = _ref.rangeViewingHint;

    var allRanges = manifest.getAllRanges();

    var matchingRange = allRanges.reduce(function (match, next) {
      if (match) return match;

      // Allow exact match range.
      if (rangeId && next.id === rangeId) {
        return next;
      }

      // Allow by viewing hint.
      if (rangeViewingHint && next.getViewingHint() && next.getViewingHint().toString() === rangeViewingHint) {
        return next;
      }

      // Also allow sequences.
      if (next.getBehavior() && next.getBehavior().toString() === 'sequence') {
        return next;
      }

      return null;
    }, null);

    if (matchingRange) {
      var _extractCanvasAndRegi = extractCanvasAndRegionsFromRange(matchingRange),
          canvases = _extractCanvasAndRegi.canvases,
          regions = _extractCanvasAndRegi.regions;

      return {
        range: matchingRange,
        id: matchingRange.id,
        canvasList: canvases,
        regionList: regions
      };
    }

    return {
      range: null,
      id: manifest.id,
      regionList: [], // @todo maybe points of interest will be somewhere else?
      canvasList: manifest.getSequenceByIndex(0).getCanvases().map(function (canvas) {
        return canvas.id;
      })
    };
  };

  RangeNavigationProvider.prototype.componentWillReceiveProps = function componentWillReceiveProps(newProps, newContext) {
    if (newProps.currentIndex !== this.props.currentIndex && newProps.currentIndex !== this.state.currentIndex) {
      // We have a controlled input.
      this.setState({ currentIndex: newProps.currentIndex });
    }

    if (newProps.rangeId !== this.props.rangeId || newProps.rangeViewingHint !== this.props.rangeViewingHint || newProps.manifest !== this.props.manifest) {
      var matchingRange = this.getMatchingRange(this.props);
      this.setState({
        currentRangeObject: matchingRange.range,
        currentRange: matchingRange.id,
        canvasList: matchingRange.canvasList,
        regionList: matchingRange.regionList || []
      });
    }
  };

  RangeNavigationProvider.prototype.render = function render() {
    var _props = this.props,
        children = _props.children,
        props = _objectWithoutProperties(_props, ['children']);

    var _state = this.state,
        currentIndex = _state.currentIndex,
        currentRange = _state.currentRange,
        canvasList = _state.canvasList,
        regionList = _state.regionList,
        currentRangeObject = _state.currentRangeObject;


    if (!props.manifest) {
      return null;
    }

    var canvas = canvasList.length !== 0 ? props.manifest.getSequenceByIndex(0).getCanvasById(canvasList[currentIndex]) : null;

    var region = regionList.length !== 0 ? regionList[currentIndex] : null;

    return functionOrMapChildren(children, _extends({}, props, {
      nextRange: this.nextRange,
      previousRange: this.previousRange,
      getNextRange: this.getNextRange(currentIndex),
      getPreviousRange: this.getPreviousRange(currentIndex),
      goToRange: this.goToRange,
      currentIndex: currentIndex,
      rangeId: currentRange,
      range: currentRangeObject,
      canvasList: canvasList,
      region: region,
      currentCanvasId: canvas ? canvas.id : null,
      canvas: canvas
    }));
  };

  return RangeNavigationProvider;
}(Component), _class.defaultProps = {
  fallbackToTop: true,
  fallbackToSequence: true,
  controlled: false
}, _temp2);


export default RangeNavigationProvider;