var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as Manifesto from 'manifesto.js';
import { FunctionOrMapChildrenType } from '../../utility/functionOrMapChildren';
import functionOrMapChildren from '../../utility/functionOrMapChildren';
import parseSelectorTarget from '../../utility/parseSelectorTarget';
import AnnotationSelector from '../../utility/AnnotationSelector';

var AnnotationProvider = function (_Component) {
  _inherits(AnnotationProvider, _Component);

  function AnnotationProvider() {
    _classCallCheck(this, AnnotationProvider);

    return _possibleConstructorReturn(this, _Component.apply(this, arguments));
  }

  AnnotationProvider.parseAnnotation = function parseAnnotation(annotation) {
    var on = annotation.getOn() || annotation.getTarget();
    return {
      on: AnnotationSelector.parse(on)
    };
  };

  AnnotationProvider.prototype.render = function render() {
    var _props = this.props,
        children = _props.children,
        annotationList = _props.annotationList,
        props = _objectWithoutProperties(_props, ['children', 'annotationList']);

    if (!annotationList) {
      return null;
    }

    var parsedList = annotationList.getResources().map(function (annotation) {
      return _extends({
        annotationList: annotationList,
        annotation: annotation
      }, AnnotationProvider.parseAnnotation(annotation));
    });

    return functionOrMapChildren(children, _extends({
      annotationList: annotationList,
      annotations: parsedList
    }, props));
  };

  return AnnotationProvider;
}(Component);

AnnotationProvider.propTypes = process.env.NODE_ENV !== "production" ? {
  // canvas: PropTypes.instanceOf(Manifesto.Canvas),
  children: FunctionOrMapChildrenType
} : {};


export default AnnotationProvider;