var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

export default function htmlElementObserver($element) {
  return function (onChange) {
    try {
      var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (_ref) {
          var type = _ref.type;

          if (type === 'attributes') {
            onChange(_extends({}, $element.dataset));
          }
        });
      });

      observer.observe($element, { attributes: true });
    } catch (e) {
      console.warn('Could not set up observer', e);
    }
  };
}