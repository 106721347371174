import * as Manifesto from 'manifesto.js';
import Manifold from './Manifold';

function getDataUriFromImages(images) {
  var infoUri = null;
  var firstImage = images[0];
  var resource = firstImage.getResource();
  var services = resource.getServices();

  if (services.length) {
    for (var i = 0; i < services.length; i++) {
      var service = services[i];
      var id = service.id;
      if (!id.endsWith('/')) {
        id += '/';
      }
      if (Manifesto.Utils.isImageProfile(service.getProfile())) {
        infoUri = id + 'info.json';
      }
    }
    return infoUri;
  }
  // no image services. return the image id
  return resource.id;
}

export default function getDataUriFromCanvas(canvas) {
  var p3Images = canvas.getP3Images();

  if (p3Images.length) {
    var firstP3Image = p3Images.shift();
    var _service = firstP3Image.getImageService();
    if (_service) {
      return _service.getInfoUri();
    }
  }

  var images = canvas.getImages();
  if (images && images.length) {
    return getDataUriFromImages(images);
  }

  // Legacy IxIF
  var service = canvas.getService(Manifesto.ServiceProfile.ixif());
  if (service) {
    return service.getInfoUri();
  }
  // return the canvas id.
  return canvas.id;
}