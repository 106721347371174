var _class, _temp;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import parseSelectorTarget from './parseSelectorTarget';

var AnnotationSelector = (_temp = _class = function () {
  AnnotationSelector.fromJsonLD = function fromJsonLD(jsonLd) {
    return AnnotationSelector.parse(jsonLd);
  };

  AnnotationSelector.fromArray = function fromArray(multipleSelectors) {
    return multipleSelectors.map(function (annotation) {
      return AnnotationSelector.parse(annotation);
    });
  };

  function AnnotationSelector(id, scale, format, language, processingLanguage, textDirection, selector) {
    _classCallCheck(this, AnnotationSelector);

    if (textDirection && textDirection !== AnnotationSelector.DIRECTION_AUTO && textDirection !== AnnotationSelector.DIRECTION_LTR && textDirection !== AnnotationSelector.DIRECTION_RTL) {
      throw new Error('textDirection must be ONE of [ltr, rtl, auto]');
    }

    this.id = id;
    this.source = (id || '').split('#')[0];
    this.format = format;
    this.language = language;
    this.processingLanguage = processingLanguage;
    this.textDirection = textDirection;
    this.selector = AnnotationSelector.parseTarget(id, scale, selector);
  }

  AnnotationSelector.fromTarget = function fromTarget(target, selector) {
    var annotationSelector = new AnnotationSelector();
    annotationSelector.source = target;
    annotationSelector.selector = selector;
    return annotationSelector;
  };

  AnnotationSelector.parse = function parse(text) {
    var scale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

    if (!text) {
      return null;
    }

    // https://www.w3.org/TR/annotation-model/#bodies-and-targets
    if (text.id) {
      return new AnnotationSelector(text.id, scale, text.format, text.language, text.processingLanguage, text.textDirection);
    }

    // https://www.w3.org/TR/annotation-model/#selectors
    if (text.source) {
      return new AnnotationSelector(text.source, scale, text.format, text.language, text.processingLanguage, text.textDirection, text.selector);
    }

    // @todo check for is text or is object and construct accordingly.
    return new AnnotationSelector(text, scale);
  };

  AnnotationSelector.parseTarget = function parseTarget(source) {
    var scale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
    var selector = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

    var toParse = source;
    if (selector && selector.type === 'FragmentSelector') {
      toParse = source + '#' + selector.value;
    }
    var target = parseSelectorTarget(toParse, scale);
    if (target !== toParse) {
      return target;
    }
    return source;
  };

  AnnotationSelector.prototype.toJSON = function toJSON() {
    if (!this.selector || this.selector.x === null || isNaN(Math.floor(this.selector.x)) || this.selector.y === null || isNaN(Math.floor(this.selector.y))) {
      return this.source;
    }

    if (this.selector.width === null || isNaN(this.selector.width) || this.selector.height === null || isNaN(this.selector.height)) {
      return this.source + '#xywh=' + Math.floor(this.selector.x) + ',' + Math.floor(this.selector.y) + ',0,0';
    }

    return this.source + '#xywh=' + Math.floor(this.selector.x) + ',' + Math.floor(this.selector.y) + ',' + Math.floor(this.selector.width) + ',' + Math.floor(this.selector.height);
  };

  AnnotationSelector.prototype.toString = function toString() {
    return this.id;
  };

  return AnnotationSelector;
}(), _class.DIRECTION_LTR = 'ltr', _class.DIRECTION_RTL = 'rtl', _class.DIRECTION_AUTO = 'auto', _temp);
export { AnnotationSelector as default };