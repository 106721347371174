import * as Manifesto from 'manifesto.js';
import parseSelectorTarget from './parseSelectorTarget';

function getCanvasId(item) {
  if (typeof item === 'string') {
    return item;
  }
  if (item.type === 'Canvas') {
    return item.id;
  }
  if (item.type === 'SpecificResource') {
    return typeof item.source === 'string' ? item.source : item.source.id;
  }
  return null;
}

function getSelector(item, canvasId) {
  var selector = parseSelectorTarget(canvasId);
  if (selector && selector !== canvasId) {
    return selector;
  }
  if (item.selector) {
    var selectorValue = item.selector.value || item.selector;
    var itemSelector = parseSelectorTarget('#' + selectorValue);

    if (itemSelector !== selectorValue) {
      return itemSelector;
    }
  }
  return null;
}

export default function extractCanvasAndRegionsFromRange(range) {
  var manifestoCanvasIds = range.getCanvasIds();
  var rangeItemsCount = range.__jsonld.items ? range.__jsonld.items.length : manifestoCanvasIds.length;

  if (manifestoCanvasIds.length === rangeItemsCount) {
    return {
      canvases: manifestoCanvasIds,
      regions: []
    };
  }

  return range.__jsonld.items.reduce(function (acc, item) {
    var canvasId = getCanvasId(item);
    if (canvasId) {
      // Push together, even if null, to maintain indexes.
      acc.canvases.push(canvasId);
      acc.regions.push(getSelector(item, canvasId));
    }

    return acc;
  }, {
    canvases: [],
    regions: []
  });
}