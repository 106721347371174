var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _class, _temp2;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from 'react';
import PropTypes from 'prop-types';

var AUTO = 'auto';

//TODO: Maybe convert it React.memo 'pure function' if we upgrade to 16.6...
var AnnotationResizers = (_temp2 = _class = function (_React$PureComponent) {
  _inherits(AnnotationResizers, _React$PureComponent);

  function AnnotationResizers() {
    var _temp, _this, _ret;

    _classCallCheck(this, AnnotationResizers);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _React$PureComponent.call.apply(_React$PureComponent, [this].concat(args))), _this), _this.createResizers = function (width, directions) {
      return Object.entries(directions.reduce(function (resizers, direction) {
        switch (direction) {
          case 'se':
          case 'ne':
          case 'sw':
          case 'nw':
            // corners
            resizers[direction] = {
              top: direction.startsWith('s') ? AUTO : 0,
              bottom: direction.startsWith('s') ? 0 : AUTO,
              left: direction.endsWith('e') ? AUTO : 0,
              right: direction.endsWith('e') ? 0 : AUTO,
              width: width,
              height: width,
              cursor: direction + '-resize'
            };
            break;
          case 'e':
          case 'w':
            // horizontal resizers with vertical boxes
            resizers[direction] = {
              top: width,
              right: direction === 'e' ? 0 : AUTO,
              left: direction === 'e' ? AUTO : 0,
              bottom: width,
              width: width,
              height: AUTO,
              cursor: direction + '-resize'
            };
            break;
          case 'n':
          case 's':
            // vertical resizers with horizontal boxes
            resizers[direction] = {
              top: direction === 's' ? AUTO : 0,
              bottom: direction === 's' ? 0 : AUTO,
              left: width,
              right: width,
              width: AUTO,
              height: width,
              cursor: direction + '-resize'
            };
            break;
        }
        return resizers;
      }, {}));
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  AnnotationResizers.prototype.render = function render() {
    var _this2 = this;

    return React.createElement(
      React.Fragment,
      null,
      this.createResizers(this.props.resizerWidth, this.props.directions).map(function (_ref) {
        var direction = _ref[0],
            styles = _ref[1];
        return React.createElement('div', {
          key: direction,
          style: _extends({}, styles, {
            //TODO: add custom styles or bem, need to pick a direction...
            position: 'absolute'
          }),
          onMouseDown: _this2.props.resizeStart(direction),
          onTouchStart: _this2.props.resizeStart(direction)
        });
      })
    );
  };

  return AnnotationResizers;
}(React.PureComponent), _class.defaultProps = {
  resizerWidth: 40,
  directions: ['se', 'ne', 'sw', 'nw', 's', 'e', 'n', 'w']
}, _temp2);
AnnotationResizers.propTypes = process.env.NODE_ENV !== "production" ? {
  resizerWidth: PropTypes.number,
  resizeStart: PropTypes.func.isRequired,
  directions: PropTypes.array
} : {};


export default AnnotationResizers;