var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _class, _temp;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import createReactContext from 'create-react-context';
import BEM from '@fesk/bem-js';

var defaultContext = {
  prefix: '',
  cssClassMap: {}
};

// Flow bem


var BemContext = createReactContext(defaultContext);

var Bem = (_temp = _class = function (_Component) {
  _inherits(Bem, _Component);

  function Bem() {
    _classCallCheck(this, Bem);

    return _possibleConstructorReturn(this, _Component.apply(this, arguments));
  }

  Bem.prototype.render = function render() {
    var _props = this.props,
        children = _props.children,
        prefix = _props.prefix,
        cssClassMap = _props.cssClassMap;

    return React.createElement(
      BemContext.Provider,
      {
        value: {
          prefix: prefix,
          cssClassMap: cssClassMap
        }
      },
      children
    );
  };

  return Bem;
}(Component), _class.defaultProps = Object.assign({}, defaultContext), _temp);


function constructBemFromContext(className, ctx) {
  if (ctx.cssClassMap[className]) {
    return BEM.block('' + ctx.prefix + ctx.cssClassMap[className]);
  }
  return BEM.block('' + ctx.prefix + className);
}

export function withBemClass(className) {
  return function (WrappedComponent) {
    return function (_Component2) {
      _inherits(_class3, _Component2);

      function _class3() {
        var _temp2, _this2, _ret;

        _classCallCheck(this, _class3);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        return _ret = (_temp2 = (_this2 = _possibleConstructorReturn(this, _Component2.call.apply(_Component2, [this].concat(args))), _this2), _this2.wrappedInstance = null, _temp2), _possibleConstructorReturn(_this2, _ret);
      }

      _class3.prototype.getWrappedInstance = function getWrappedInstance() {
        return this.wrappedInstance;
      };

      _class3.prototype.render = function render() {
        var _this3 = this;

        return React.createElement(
          BemContext.Consumer,
          null,
          function (bemCtx) {
            return React.createElement(WrappedComponent, _extends({
              ref: function ref(wrappedInstance) {
                _this3.wrappedInstance = wrappedInstance;
              }
            }, _this3.props, {
              bem: constructBemFromContext(className, Object.assign({}, defaultContext, bemCtx))
            }));
          }
        );
      };

      return _class3;
    }(Component);
  };
}

export default Bem;