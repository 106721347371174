function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';

var ObservableElement = function (_Component) {
  _inherits(ObservableElement, _Component);

  function ObservableElement() {
    _classCallCheck(this, ObservableElement);

    return _possibleConstructorReturn(this, _Component.apply(this, arguments));
  }

  ObservableElement.prototype.componentDidMount = function componentDidMount() {
    var _this2 = this;

    if (this.props.observer) {
      this.props.observer(function (newState) {
        return _this2.setState(newState);
      });
    }
    this.setState(function () {
      return _this2.props.initialProps;
    });
  };

  ObservableElement.prototype.render = function render() {
    var Element = this.props.render;
    return React.createElement(Element, this.state);
  };

  return ObservableElement;
}(Component);

export default ObservableElement;