function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { PureComponent } from 'react';
import * as Manifesto from 'manifesto.js';
import * as PropTypes from 'prop-types';

var LocaleString = function (_PureComponent) {
  _inherits(LocaleString, _PureComponent);

  function LocaleString() {
    _classCallCheck(this, LocaleString);

    return _possibleConstructorReturn(this, _PureComponent.apply(this, arguments));
  }

  LocaleString.prototype.render = function render() {
    var _props = this.props,
        children = _props.children,
        renderList = _props.renderList,
        lang = _props.lang;

    if (!children) {
      return null;
    }

    if (Array.isArray(children)) {
      return Manifesto.LanguageMap.getValue(children);
    }

    if (children[lang]) {
      return renderList ? renderList(children[lang]) : Manifesto.LanguageMap.getValue(children[lang]);
    }

    var values = Object.values(children);
    if (values.length) {
      return renderList ? renderList(values[0]) : React.createElement('span', { dangerouslySetInnerHTML: { __html: values[0].join('\n') } });
    }

    return '';
  };

  return LocaleString;
}(PureComponent);

export default LocaleString;