var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _class, _temp2;

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import OpenSeadragon from 'openseadragon';
import OpenSeadragonViewer from '../OpenSeadragonViewer/OpenSeadragonViewer';
import functionOrMapChildren from '../../utility/functionOrMapChildren';

var OpenSeadragonViewport = (_temp2 = _class = function (_Component) {
  _inherits(OpenSeadragonViewport, _Component);

  function OpenSeadragonViewport() {
    var _temp, _this, _ret;

    _classCallCheck(this, OpenSeadragonViewport);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      scale: 1,
      panning: false
    }, _this.unmounted = false, _this.onViewportChange = function (viewer) {
      return function (e) {
        var bounds = viewer.viewport.getBoundsNoRotate();
        var constrainedBounds = viewer.viewport._applyBoundaryConstraints(bounds);

        if (bounds.x !== constrainedBounds.x || bounds.y !== constrainedBounds.y) {
          if (_this.props.onConstrain) {
            var factor = viewer.viewport._containerInnerSize.x * viewer.viewport.getZoom();
            _this.props.onConstrain(viewer, (bounds.x - constrainedBounds.x) * factor, (bounds.y - constrainedBounds.y) * factor);
          }
          _this.setState({ constrained: true });
        } else {
          if (_this.props.onUnconstrain) {
            _this.props.onUnconstrain(viewer);
          }
          _this.setState({ constrained: false });
        }
      };
    }, _this.onDragStart = function (viewer) {
      return function () {
        if (_this.state.panning === false) {
          _this.setState({ panning: true });

          if (_this.props.onDragStart) {
            _this.props.onDragStart(viewer);
          }
        }
      };
    }, _this.onDragStop = function (viewer) {
      return function () {
        _this.setState({ panning: false });
        if (_this.props.onDragStop) {
          _this.props.onDragStop(viewer);
        }
      };
    }, _this.setViewer = function (viewer) {
      var onImageLoaded = _this.props.onImageLoaded;

      _this.viewer = viewer;
      if (onImageLoaded) {
        onImageLoaded(viewer);
      }
      _this.bindEvents(viewer);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  OpenSeadragonViewport.prototype.componentDidMount = function componentDidMount() {
    if (this.viewer) {
      this.bindEvents(this.viewer);
    }
  };

  OpenSeadragonViewport.prototype.componentWillUnmount = function componentWillUnmount() {
    this.unmounted = true;
  };

  OpenSeadragonViewport.prototype.bindEvents = function bindEvents(viewer) {
    var _this2 = this;

    viewer.addHandler('update-viewport', function () {
      return _this2.resize(viewer);
    });
    viewer.addHandler('open', function () {
      return _this2.resize(viewer);
    });
    viewer.addHandler('canvas-drag', this.onDragStart(viewer));
    viewer.addHandler('canvas-drag-end', this.onDragStop(viewer));
    viewer.addHandler('viewport-change', this.onViewportChange(viewer));
    this.resize(viewer);
  };

  OpenSeadragonViewport.prototype.resize = function resize(viewer) {
    var _this3 = this;

    var canvas = this.props.canvas;

    var firstImage = viewer.world.getItemAt(0);
    if (!firstImage) {
      return;
    }
    var imgWidth = canvas.getWidth();
    var imgHeight = canvas.getHeight();
    var imgAspectRatio = imgWidth / imgHeight;
    var boundsRect = viewer.viewport.getBounds(true);
    var viewportOrigin = new OpenSeadragon.Point(boundsRect.x, boundsRect.y * imgAspectRatio);

    var viewportWidth = boundsRect.width;
    var viewportHeight = boundsRect.height * imgAspectRatio;

    // Redraw
    var viewportZoom = viewer.viewport.getZoom(true);
    var zoom = firstImage.viewportToImageZoom(viewportZoom);
    var imageRatio = firstImage._scaleSpring.current.value * firstImage.viewport._containerInnerSize.x / firstImage.source.dimensions.x;

    var isZoomedOut = viewportZoom.toFixed(2) <= viewer.viewport.getMinZoom().toFixed(2);

    var rotation = viewer.viewport.getRotation();

    var x = (viewportOrigin.x / imgWidth - viewportOrigin.x) / viewportWidth * viewer.container.clientWidth;
    var y = (viewportOrigin.y / imgHeight - viewportOrigin.y) / viewportHeight * viewer.container.clientHeight;

    if (this.viewer && this.unmounted === false) {
      // Set position for parents.
      if (this.props.getPosition) {
        this.props.getPosition({
          x: x,
          y: y,
          zoom: zoom,
          scale: this.state.scale,
          rotation: rotation,
          imageRatio: imageRatio,
          isZoomedOut: isZoomedOut
        });
      }

      // Set state for transform.
      this.setState(function () {
        return {
          canvasScale: _this3.state.scale * zoom
        };
      });
    }
  };

  OpenSeadragonViewport.prototype.render = function render() {
    var _state = this.state,
        canvasScale = _state.canvasScale,
        panning = _state.panning;

    var _props = this.props,
        children = _props.children,
        props = _objectWithoutProperties(_props, ['children']);

    if (!children) {
      return React.createElement(OpenSeadragonViewer, _extends({}, props, {
        canvasScale: canvasScale,
        onImageLoaded: this.setViewer
      }));
    }

    return functionOrMapChildren(children, _extends({
      canvasScale: canvasScale,
      onImageLoaded: this.setViewer,
      panning: panning
    }, props));
  };

  return OpenSeadragonViewport;
}(Component), _class.defaultProps = {
  viewportController: true
}, _temp2);


export default OpenSeadragonViewport;