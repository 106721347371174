var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';

/**
 * Creates valid transforms for FF + SF9
 * @todo move to utility.
 */
function transformGenerator(_ref) {
  var dX = _ref.x,
      dY = _ref.y,
      dScale = _ref.scale,
      dRotation = _ref.rotation;

  var parts = [];
  var floor = function floor(val) {
    var retVal = val.toFixed(4);
    if (retVal < 0.001 && retVal > -0.001) {
      return 0;
    }
    return retVal || 0;
  };
  if (dX || dY) {
    parts.push('translate(' + floor(dX) + 'px,' + floor(dY) + 'px)');
  }
  if (dScale) {
    parts.push('scale(' + floor(dScale) + ')');
  }
  if (dRotation) {
    parts.push('rotate(' + floor(dRotation) + ')');
  }
  return parts.join(' ');
}

var Viewport = function (_Component) {
  _inherits(Viewport, _Component);

  function Viewport() {
    var _temp, _this, _ret;

    _classCallCheck(this, Viewport);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      x: 0,
      y: 0,
      zoom: 1,
      scale: 1,
      rotation: 0,
      isLoading: true
    }, _this.unmounted = false, _this.onUpdateViewport = function (_ref2) {
      var x = _ref2.x,
          y = _ref2.y,
          zoom = _ref2.zoom,
          scale = _ref2.scale,
          rotation = _ref2.rotation,
          imageRatio = _ref2.imageRatio,
          _ref2$isZoomedOut = _ref2.isZoomedOut,
          isZoomedOut = _ref2$isZoomedOut === undefined ? true : _ref2$isZoomedOut;

      if (_this.umounted) return;
      _this.setState({
        isLoading: false,
        x: x,
        y: y,
        zoom: zoom,
        scale: scale,
        rotation: rotation,
        imageRatio: imageRatio,
        isZoomedOut: isZoomedOut
      });

      if (_this.props.onUpdateViewport) {
        _this.props.onUpdateViewport({
          x: x,
          y: y,
          zoom: zoom,
          scale: scale,
          rotation: rotation,
          imageRatio: imageRatio,
          isZoomedOut: isZoomedOut
        });
      }
    }, _this.viewer = null, _this.getRef = function (viewer) {
      _this.viewer = viewer;
    }, _this.goToRect = function (bounds, padding, speed) {
      if (_this.viewer && _this.viewer.goToRect) {
        _this.viewer.goToRect(bounds, padding, speed);
      }
    }, _this.getMinZoom = function () {
      if (_this.viewer && _this.viewer.getMinZoom) {
        return _this.viewer.getMinZoom();
      }
      return 1;
    }, _this.getMaxZoom = function () {
      if (_this.viewer && _this.viewer.getMaxZoom) {
        return _this.viewer.getMaxZoom();
      }
      return 0;
    }, _this.getZoom = function () {
      if (_this.viewer && _this.viewer.getZoom) {
        return _this.viewer.getZoom();
      }
      return 0;
    }, _this.zoomIn = function (speed) {
      if (_this.viewer && _this.viewer.zoomIn) {
        _this.viewer.zoomIn(speed);
      }
    }, _this.zoomOut = function (speed) {
      if (_this.viewer && _this.viewer.zoomOut) {
        _this.viewer.zoomOut(speed);
      }
    }, _this.resetView = function (speed) {
      if (_this.viewer && _this.viewer.resetView) {
        _this.viewer.resetView(speed);
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  Viewport.prototype.componentDidMount = function componentDidMount() {
    if (this.props.setRef) {
      this.props.setRef(this);
    }
  };

  Viewport.prototype.componentWillReceiveProps = function componentWillReceiveProps(newProps) {
    if (this.props.setRef !== newProps.setRef) {
      newProps.setRef(this);
    }
  };

  Viewport.prototype.componentWillUnmount = function componentWillUnmount() {
    this.umounted = true;
  };

  Viewport.prototype.getChildRatio = function getChildRatio(_ref3) {
    var ratioFromMaxWidth = _ref3.ratioFromMaxWidth,
        ratio = _ref3.ratio,
        scale = _ref3.scale;

    var width = this.props.width;

    if (ratioFromMaxWidth) {
      return ratioFromMaxWidth / width;
    }

    if (scale) {
      return scale;
    }

    if (ratio) {
      return ratio;
    }

    return window.innerWidth / width; // Defaults to max width of screen.
  };

  Viewport.prototype.shouldComponentUpdate = function shouldComponentUpdate(newProps, newState) {
    var _state = this.state,
        x = _state.x,
        y = _state.y,
        zoom = _state.zoom,
        scale = _state.scale,
        rotation = _state.rotation,
        imageRatio = _state.imageRatio,
        isZoomedOut = _state.isZoomedOut,
        isLoading = _state.isLoading;
    var _props = this.props,
        maxWidth = _props.maxWidth,
        maxHeight = _props.maxHeight,
        children = _props.children,
        style = _props.style,
        setRef = _props.setRef,
        tileSources = _props.tileSources,
        currentCanvas = _props.currentCanvas;


    return x !== newState.x || y !== newState.y || zoom !== newState.zoom || scale !== newState.scale || rotation !== newState.rotation || imageRatio !== newState.imageRatio || isZoomedOut !== newState.isZoomedOut || isLoading !== newState.isLoading || setRef !== newProps.setRef || maxWidth !== newProps.maxWidth || maxHeight !== newProps.maxHeight || children !== newProps.children || style !== newProps.style || tileSources !== newProps.tileSources || currentCanvas !== newProps.currentCanvas;
  };

  Viewport.prototype.render = function render() {
    var _this2 = this;

    var _state2 = this.state,
        x = _state2.x,
        y = _state2.y,
        zoom = _state2.zoom,
        scale = _state2.scale,
        rotation = _state2.rotation,
        imageRatio = _state2.imageRatio,
        isZoomedOut = _state2.isZoomedOut,
        isLoading = _state2.isLoading;

    var _props2 = this.props,
        maxWidth = _props2.maxWidth,
        maxHeight = _props2.maxHeight,
        children = _props2.children,
        style = _props2.style,
        props = _objectWithoutProperties(_props2, ['maxWidth', 'maxHeight', 'children', 'style']);

    return React.createElement(
      'div',
      {
        style: _extends({
          maxWidth: Number.isNaN(maxWidth) ? 'auto' : maxWidth,
          maxHeight: Number.isNaN(maxHeight) ? 'auto' : maxHeight,
          position: 'relative',
          display: 'inline-block',
          overflow: 'hidden',
          width: '100%'
        }, style)
      },
      React.Children.map(children, function (child) {
        if (child === null || child.props['data-static']) {
          return child;
        }
        if (child.props.viewportController === true) {
          return React.cloneElement(child, _extends({
            getPosition: _this2.onUpdateViewport,
            getRef: _this2.getRef,
            maxWidth: maxWidth,
            maxHeight: maxHeight
          }, props));
        }
        if (isLoading === true) {
          return React.createElement('div', { 'data-loading': true });
        }

        var ratio = _this2.getChildRatio(child.props);
        return React.cloneElement(child, _extends({
          style: _extends({
            transform: transformGenerator({
              x: x,
              y: y,
              scale: zoom * scale / ratio,
              rotation: rotation
            }),
            transformOrigin: '0 0 0',
            maxHeight: maxHeight
          }, child.props.style, {
            width: _this2.props.width * ratio,
            height: _this2.props.height * ratio,
            position: 'absolute',
            top: 0,
            left: 0
          }),
          position: {
            x: x * ratio,
            y: y * ratio,
            zoom: zoom,
            scale: scale / ratio,
            rotation: rotation,
            imageRatio: imageRatio,
            isZoomedOut: isZoomedOut
          },
          ratio: ratio,
          maxWidth: maxWidth
        }, props));
      })
    );
  };

  return Viewport;
}(Component);

export default Viewport;