import '@iiif/manifold';

global.$ = global.$ ? global.$ : {};
global.$.ajax = global.$.ajax ? global.$.ajax : function (_ref) {
  var url = _ref.url,
      type = _ref.type,
      dataType = _ref.dataType,
      beforeSend = _ref.beforeSend;

  var fake = {
    headers: {},
    setRequestHeader: function setRequestHeader(name, value) {
      this.headers[name] = value;
    }
  };
  if (beforeSend) {
    beforeSend(fake);
  }
  return {
    done: function done(func) {
      var _this = this;

      var obj = {
        func: function func(e) {
          _this.err = e;
        },
        fail: function fail(fn) {
          this.func = fn;
        }
      };
      fetch(url).then(function (k) {
        return dataType === 'json' ? k.json() : k.text();
      }).then(func).catch(function (e) {
        return obj.func(e);
      });

      return obj;
    },
    fail: function fail() {}
  };
};
/*
$.ajax({
    url: that.dataUri,
    type: type,
    dataType: 'json',
    beforeSend: function (xhr) {
        if (accessToken) {
            xhr.setRequestHeader("Authorization", "Bearer " + accessToken.accessToken);
        }
    }
})
*/

export default global.Manifold;