var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _class, _temp;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import OpenSeadragon from 'openseadragon';
import React, { Component } from 'react';

var OpenSeadragonViewer = (_temp = _class = function (_Component) {
  _inherits(OpenSeadragonViewer, _Component);

  function OpenSeadragonViewer() {
    _classCallCheck(this, OpenSeadragonViewer);

    var _this = _possibleConstructorReturn(this, _Component.call(this));

    _this.state = {
      fallback: false
    };
    _this.viewer = null;
    _this.element = null;

    _this.getMinZoom = function () {
      if (_this.viewer) {
        return _this.viewer.viewport.getMinZoom();
      }
      return 0;
    };

    _this.getMaxZoom = function () {
      if (_this.viewer) {
        return _this.viewer.viewport.getMaxZoom();
      }
      return 1;
    };

    _this.getZoom = function () {
      if (_this.viewer) {
        return _this.viewer.viewport.getZoom();
      }
      return 0;
    };

    _this.zoomIn = function (speed) {
      var zoomBy = 1 / 0.7;
      if (_this.getZoom() < _this.getMaxZoom()) {
        if (_this.getZoom() * zoomBy < _this.getMaxZoom()) {
          _this.viewportAction('zoomBy', [zoomBy], speed);
        } else {
          _this.viewportAction('zoomTo', [_this.getMaxZoom()], speed);
        }
      }
    };

    _this.zoomOut = function (speed) {
      var zoomBy = 0.7;
      if (_this.getZoom() > _this.getMinZoom()) {
        if (_this.getZoom() * zoomBy > _this.getMinZoom()) {
          _this.viewportAction('zoomBy', [zoomBy], speed);
        } else {
          _this.viewportAction('zoomTo', [_this.getMinZoom()], speed);
        }
      }
    };

    _this.resetView = function (speed) {
      _this.viewportAction('goHome', [], speed);
    };

    _this.setRef = function (el) {
      return _this.element = el;
    };

    _this.viewerReady = new Promise(function (resolve) {
      _this.resolveViewer = resolve;
    });
    return _this;
  }

  OpenSeadragonViewer.prototype.asyncAddTile = function asyncAddTile(args) {
    var _this2 = this;

    return new Promise(function (success, err) {
      if (!_this2.viewer) {
        return null;
      }

      try {
        _this2.viewer.addTiledImage.call(_this2.viewer, _extends({ success: success }, args));
      } catch (e) {
        err(e);
      }
    });
  };

  OpenSeadragonViewer.prototype.createRelativePoint = function createRelativePoint(x, y) {
    if (!this.viewer) {
      return null;
    }

    return this.viewer.viewport.viewerElementToImageCoordinates(new OpenSeadragon.Point(x, y));
  };

  OpenSeadragonViewer.prototype.createViewportPoint = function createViewportPoint(x, y) {
    if (!this.viewer) {
      return null;
    }
    return this.viewer.viewport.imageToViewerElementCoordinates(new OpenSeadragon.Point(x, y));
  };

  OpenSeadragonViewer.prototype.getImageSize = function getImageSize() {
    return {
      width: this.props.width,
      height: this.props.height
    };
  };

  OpenSeadragonViewer.prototype.goToInitialBounds = function goToInitialBounds() {
    var speed = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0.0000001;

    if (!this.viewer) {
      return null;
    }
    if (this.props.initialBounds) {
      this.goToRect(this.props.initialBounds, 0, speed);
    } else {
      this.viewer.viewport.goHome(true);
    }
  };

  OpenSeadragonViewer.prototype.componentWillReceiveProps = function componentWillReceiveProps(newProps) {
    var _this3 = this;

    var tileSources = this.props.tileSources;

    if (newProps.tileSources !== tileSources && this.viewer) {
      this.viewer.close();
      Promise.all(newProps.tileSources.map(function (tileSource) {
        return _this3.asyncAddTile({ tileSource: tileSource });
      })).then(function (e) {
        if (_this3.viewer) {
          _this3.goToInitialBounds();
          if (newProps.onImageLoaded) {
            newProps.onImageLoaded(_this3.viewer, e);
          }
        }
      });
    }
  };

  OpenSeadragonViewer.prototype.componentDidMount = function componentDidMount() {
    var _this4 = this;

    var _props = this.props,
        getRef = _props.getRef,
        onImageLoaded = _props.onImageLoaded,
        tileSources = _props.tileSources,
        osdOptions = _props.osdOptions;

    if (!tileSources) {
      console.error('Something went wrong, we cannot display the open sea dragon');
      this.setState({ fallback: true });
      return;
    }
    this.setState({ fallback: false });
    this.viewer = new OpenSeadragon(_extends({
      element: this.element,
      ajaxWithCredentials: false,
      showNavigator: true,
      showRotationControl: true,
      defaultZoomLevel: 0,
      maxZoomPixelRatio: 1,
      navigatorPosition: 'BOTTOM_RIGHT',
      animationTime: 0.3,
      immediateRender: true,
      preserveViewport: true,
      blendTime: 0.1,
      minPixelRatio: 0.5,
      visibilityRatio: 0.65,
      minZoomImageRatio: 1,
      constrainDuringPan: false,
      showSequenceControl: false,
      showNavigationControl: false,
      showZoomControl: true,
      showHomeControl: false,
      showFullPageControl: false,
      sequenceMode: true
    }, osdOptions));
    if (getRef) {
      getRef(this);
    }
    if (tileSources.length) {
      Promise.all(tileSources.map(function (tileSource) {
        return _this4.asyncAddTile({ tileSource: tileSource });
      })).then(function (e) {
        if (_this4.viewer) {
          _this4.goToInitialBounds();
        }
        if (onImageLoaded) {
          onImageLoaded(_this4.viewer, e);
        }
      });
    }

    this.ensureViewerReady();
  };

  OpenSeadragonViewer.prototype.ensureViewerReady = function ensureViewerReady() {
    var _this5 = this;

    if (!this.viewer) {
      return null;
    }

    var x = this.viewer.viewport._contentSize.x;

    if (x > 1) {
      return this.resolveViewer();
    }
    // Sometimes this does not always work...
    var handle = function handle() {
      if (_this5.viewer) {
        _this5.viewer.removeHandler('resize', handle);
      }

      _this5.resolveViewer();
    };
    this.viewer.addHandler('resize', handle);
    // Failing that, we do interval, for 5 seconds.
    var state = { count: 0, interval: 250, seconds: 5 };
    var interval = setInterval(function () {
      if (state.count >= state.seconds * 1000) {
        return clearInterval(interval);
      }
      state.count += state.interval;
      if (_this5.viewer && _this5.viewer.viewport._contentSize.x > 1) {
        _this5.resolveViewer();
        clearInterval(interval);
      }
    }, state.interval);
  };

  OpenSeadragonViewer.prototype.goToRect = function goToRect(_ref) {
    var x = _ref.x,
        y = _ref.y,
        width = _ref.width,
        height = _ref.height;
    var padding = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var speed = arguments[2];

    if (!this.viewer) {
      return null;
    }

    var selectHighlight = this.viewer.viewport.imageToViewportRectangle(new OpenSeadragon.Rect(x - padding / 2, y - padding / 2, width + padding, height + padding, 0));

    this.viewportAction('fitBounds', [selectHighlight, speed === null], speed);
  };

  OpenSeadragonViewer.prototype.panTo = function panTo(x, y, speed) {
    this.viewportAction('panTo', [new OpenSeadragon.Point(x, y)], speed);
  };

  OpenSeadragonViewer.prototype.zoomTo = function zoomTo(zoom) {
    var refPoint = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    var immediately = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var speed = arguments[3];

    this.viewportAction('zoomTo', [zoom, refPoint, immediately], speed);
  };

  OpenSeadragonViewer.prototype.viewportAction = function viewportAction(name) {
    var _this6 = this;

    var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var speed = arguments[2];

    if (!this.viewer) {
      console.warn('Viewport not available to run action: ' + name, args);
      return null;
    }
    var func = this.viewer.viewport[name];

    if (func) {
      this.viewerReady.then(function () {
        if (speed || speed === 0) {
          return _this6.runAtSpeed(speed, function () {
            return _this6.viewer ? func.apply(_this6.viewer.viewport, args) : null;
          });
        }
        return _this6.viewer ? func.apply(_this6.viewer.viewport, args) : null;
      });
    } else {
      console.warn('Viewport action ' + name + ' not found', args);
    }
  };

  OpenSeadragonViewer.prototype.runAtSpeed = function runAtSpeed(speed, callback) {
    if (!this.viewer) {
      return null;
    }

    var centerSpringX = this.viewer.viewport.centerSpringX.animationTime;
    var centerSpringY = this.viewer.viewport.centerSpringY.animationTime;
    var zoomSprint = this.viewer.viewport.zoomSpring.animationTime;

    this.viewer.viewport.centerSpringX.animationTime = speed;
    this.viewer.viewport.centerSpringY.animationTime = speed;
    this.viewer.viewport.zoomSpring.animationTime = speed;

    callback();

    if (!this.viewer) {
      return null;
    }

    this.viewer.viewport.centerSpringX.animationTime = centerSpringX;
    this.viewer.viewport.centerSpringY.animationTime = centerSpringY;
    this.viewer.viewport.zoomSpring.animationTime = zoomSprint;
  };

  OpenSeadragonViewer.prototype.getPosition = function getPosition() {
    if (!this.viewer) {
      return null;
    }

    var _viewer$viewport$getC = this.viewer.viewport.getCenter(),
        x = _viewer$viewport$getC.x,
        y = _viewer$viewport$getC.y;

    if (!this.viewer) {
      return null;
    }

    return { x: x, y: y, zoom: this.getZoom() };
  };

  OpenSeadragonViewer.prototype.render = function render() {
    var _props2 = this.props,
        height = _props2.height,
        width = _props2.width,
        maxWidth = _props2.maxWidth,
        maxHeight = _props2.maxHeight,
        showControls = _props2.showControls,
        _props2$useMaxDimensi = _props2.useMaxDimensions,
        useMaxDimensions = _props2$useMaxDimensi === undefined ? false : _props2$useMaxDimensi;

    var heightRatio = maxHeight ? maxHeight / height : 1;
    var widthRatio = maxWidth ? maxWidth / width : 1;
    var scale = heightRatio < widthRatio ? heightRatio : widthRatio;
    var actualHeight = useMaxDimensions && maxHeight ? maxHeight : height * scale;
    var actualWidth = useMaxDimensions && maxWidth ? maxWidth : width * scale;

    if (this.state.fallback) {
      return null;
    }

    return React.createElement(
      'div',
      {
        style: {
          position: 'relative',
          height: actualHeight,
          width: '100%'
        }
      },
      showControls ? React.createElement(
        'div',
        null,
        React.createElement(
          'div',
          { onClick: this.zoomIn },
          '+'
        ),
        React.createElement(
          'div',
          { onClick: this.zoomOut },
          '-'
        )
      ) : null,
      React.createElement('div', {
        ref: this.setRef,
        style: { height: actualHeight, width: '100%' }
      })
    );
  };

  return OpenSeadragonViewer;
}(Component), _class.defaultProps = {
  osdOptions: {},
  useMaxDimensions: false
}, _temp);


export default OpenSeadragonViewer;