var _class, _temp;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import * as Manifesto from 'manifesto.js';
import { withBemClass } from '../Bem/Bem';

var AnnotationDetail = (_temp = _class = function (_Component) {
  _inherits(AnnotationDetail, _Component);

  function AnnotationDetail() {
    _classCallCheck(this, AnnotationDetail);

    return _possibleConstructorReturn(this, _Component.apply(this, arguments));
  }

  AnnotationDetail.prototype.render = function render() {
    var _props = this.props,
        annotation = _props.annotation,
        onClose = _props.onClose,
        closeText = _props.closeText,
        bem = _props.bem;

    var resource = annotation.getResource();
    var bodies = annotation.getBody();

    if (bodies.length) {
      return React.createElement(
        'div',
        { className: bem },
        bodies.map(function (body, key) {
          return React.createElement(
            'div',
            { key: key },
            body.__jsonld.label ? React.createElement(
              'h1',
              { className: bem.element('label') },
              body.__jsonld.label
            ) : null,
            React.createElement('div', {
              className: bem.element('value'),
              key: key,
              dangerouslySetInnerHTML: { __html: body.__jsonld.value }
            }),
            onClose ? React.createElement(
              'button',
              { className: bem.element('close'), onClick: onClose },
              closeText
            ) : null
          );
        })
      );
    }

    if (resource && resource.getProperty('within')) {
      var toDisplay = resource.getProperty('within');
      return React.createElement(
        'div',
        { className: bem },
        React.createElement(
          'h1',
          { className: bem.element('label') },
          toDisplay.label
        ),
        React.createElement(
          'p',
          { className: bem.element('description') },
          toDisplay.description
        ),
        onClose ? React.createElement(
          'button',
          { className: bem.element('close'), onClick: onClose },
          closeText
        ) : null
      );
    }

    return React.createElement(
      'div',
      { className: bem },
      React.createElement(
        'h1',
        { className: bem.element('label') },
        annotation.getLabel()
      ),
      React.createElement(
        'p',
        { className: bem.element('description') },
        annotation.getProperty('description')
      ),
      onClose ? React.createElement(
        'button',
        { className: bem.element('close'), onClick: onClose },
        closeText
      ) : null
    );
  };

  return AnnotationDetail;
}(Component), _class.defaultProps = {
  closeText: 'close'
}, _temp);


export default withBemClass('annotation-detail')(AnnotationDetail);